var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"item_inner change_pass"},[_c('div',{staticClass:"service_title"},[_vm._v(_vm._s(_vm.$t("Change password")))]),_c('div',{staticClass:"section_description"},[_vm._v(" "+_vm._s(_vm.$t("Password required"))+" ")]),_c('ValidationObserver',{ref:"change_pass_validation",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('ValidationProvider',{attrs:{"name":"old_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(_vm._s(_vm.$t("Old password")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.old_password),expression:"old_password"}],staticClass:"last_password",attrs:{"type":"password","autocomplete":"off","name":"reg_pass"},domProps:{"value":(_vm.old_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.old_password=$event.target.value}}}),_c('span',{staticClass:"error_hint"},[_c('span',{staticClass:"standard_hint"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"individual_hint"},[_vm._v("at least 8 characters")])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password_conf","rules":"required|password","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(_vm._s(_vm.$t("New password")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"password_field",attrs:{"type":"password","autocomplete":"off","name":"reg_pass"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"error_hint"},[_c('span',{staticClass:"standard_hint"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"individual_hint"},[_vm._v("at least 8 characters")])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(" "+_vm._s(_vm.$t("Confirm new password"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"confirm_field",attrs:{"type":"password","name":"pass_confirm","autocomplete":"off"},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirm=$event.target.value}}}),_c('span',{staticClass:"error_hint"},[_vm._v("passwords do not match")])])]}}],null,true)}),_c('button',{staticClass:"validate_btn change_pass"},[_vm._v(" "+_vm._s(_vm.$t("Change password"))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }