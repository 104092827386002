var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services_inner"},[(!_vm.service_loading)?_c('div',[_c('div',{staticClass:"title_row"},[_c('div',{staticClass:"service_title"},[_vm._v(_vm._s(_vm.$t("My services")))]),_c('div',{staticClass:"select_block"},[_c('v-select',{attrs:{"placeholder":_vm.$t("Sort by"),"options":_vm.contract_options,"reduce":function (contract) { return contract.id; },"clearable":false},on:{"input":_vm.changeContract},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({staticClass:"drop_icon"},'span',attributes,false))]}}],null,false,555555052),model:{value:(_vm.contract_id),callback:function ($$v) {_vm.contract_id=$$v},expression:"contract_id"}})],1)]),_c('div',{staticClass:"order_list"},[_c('ul',[_c('li',[_c('div',{staticClass:"list_head"},[_c('div',{staticClass:"service_name"},[_vm._v(_vm._s(_vm.$t("Service")))]),_c('div',{staticClass:"package_name"},[_vm._v(_vm._s(_vm.$t("Package")))]),_c('div',{staticClass:"inclusions_block"},[_vm._v(_vm._s(_vm.$t("Inclusions")))]),_c('div',{staticClass:"payment_subject"},[_vm._v(_vm._s(_vm.$t("Monthly fee")))]),_c('div',{staticClass:"status_block"},[_vm._v(" "+_vm._s(_vm.$t("Status"))+" "),_c('span',{staticClass:"icon_quest"})])])]),_c('li',[_c('div',{staticClass:"order_head"},[_c('div',{staticClass:"service_name",attrs:{"data-mobile":"Ծառայություն"}},[_vm._v(" "+_vm._s(_vm.service && _vm.service.name ? _vm.service.name : "")+" ")]),_c('div',{staticClass:"package_name",attrs:{"data-mobile":"Փաթեթ"}},[_c('div',[_vm._v(" "+_vm._s(_vm.contract_service && _vm.contract_service.service_package ? _vm.contract_service.service_package : "")+" ")])]),_c('div',{staticClass:"inclusions_block",attrs:{"data-mobile":"Ներառումներ"}},[(
                  _vm.service &&
                  _vm.service.packages &&
                  _vm.service.packages.length &&
                  _vm.service.packages[0].provided_services.length
                )?_c('div',{staticClass:"inclusions_inner"},_vm._l((_vm.service.packages[0].provided_services),function(pr_service){return _c('div',{key:pr_service.id},[_c('span',[_vm._v(_vm._s(pr_service.name)+" ")])])}),0):_vm._e()]),(
                _vm.service &&
                _vm.service.packages &&
                _vm.service.packages.length &&
                _vm.service.packages[0].provided_services.length
              )?_c('div',{staticClass:"payment_subject",attrs:{"data-mobile":"Ամսավճար"}},[_vm._v(" "+_vm._s(_vm.service.packages[0].amount)+" ֏ ")]):_vm._e(),_c('div',{staticClass:"status_block status_on",attrs:{"data-mobile":"Ստատուս"}},[_c('span',[_vm._v(_vm._s(_vm.$t("On")))])])])])])])]):_c('div',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"heading, table-row-divider, table-row-divider,table-row-divider, button"}},'v-skeleton-loader',_vm.attrs,false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }